import moment, { Moment } from "moment";
import { API } from "aws-amplify";

import { Schedule, UpdateScheduleInput } from "API";
import { getSchedule } from "graphql/queries";
import { updateSchedule } from "graphql/mutations";

/** フェーズ識別子 */
type PhaseType =
  | "beforeStockingDate"
  | "stockingDate"
  | "beforePackagingDate"
  | "packagingDate"
  | "beforeShippingDate"
  | "shippingDate"
  | "beforeCutDate"
  | "cutDate"
  | "afterCutDate";

/** フェーズの列挙体。画面上左から並ぶ順番通りに上から列挙すること。 */
const PhaseEnum: PhaseType[] = [
  "beforeStockingDate",
  "stockingDate",
  "beforePackagingDate",
  "packagingDate",
  "beforeShippingDate",
  "shippingDate",
  "beforeCutDate",
  "cutDate",
  "afterCutDate",
];

// GQLを使ってscheduleをIDで取得する
export const getScheduleById = async (id: string): Promise<Schedule> => {
  const s = (
    await API.graphql({
      query: getSchedule,
      variables: { id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
  ).data.getSchedule;

  return {
    __typename: "Schedule",
    id: s.id,
    projectId: s.projectId,
    name: s.name,
    note: s.note,
    beforeStockingName: s.beforeStockingName,
    beforeStockingDate: s.beforeStockingDate,
    stockingDate: s.stockingDate,
    beforePackagingName: s.beforePackagingName,
    beforePackagingDate: s.beforePackagingDate,
    packagingDate: s.packagingDate,
    beforeShippingName: s.beforeShippingName,
    beforeShippingDate: s.beforeShippingDate,
    shippingDate: s.shippingDate,
    shipType: s.shipType,
    beforeCutName: s.beforeCutName,
    beforeCutDate: s.beforeCutDate,
    cutDate: s.cutDate,
    afterCutName: s.afterCutName,
    afterCutDate: s.afterCutDate,
    createdAt: s.createdAt,
    updatedAt: s.updatedAt,
  };
};

// GQLを使ってscheduleを更新する
export const updateScheduleByInput = async (
  input: UpdateScheduleInput
): Promise<void> => {
  return await API.graphql({
    query: updateSchedule,
    variables: { input },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
};

/**
 * targetがどこのphaseの日にちか確認する
 * @param schedule スケジュール情報
 * @param targetDate 確認したい日にち
 * @return 対象フェーズ、見つからない場合はnull
 */
export const getPhase = (
  schedule: Schedule,
  targetDate: string
): PhaseType | null => {
  // 各日付のどの期間に含まれているか確認
  let result = -1; // フェーズを示す。idxのひとつ前に検査したフェーズにする
  let startDate = null;
  let endDate = null;
  // idx=0は(入荷)範囲がないため除外
  for (let idx = 0; idx < PhaseEnum.length; idx++) {
    // 日付未設定の場合は次の対象を探す
    if (!schedule[PhaseEnum[idx]]) continue;
    // 初回の日付を設定(比較対象がないためcontinue)
    if (!endDate) {
      endDate = schedule[PhaseEnum[idx]];
      result = idx;
      continue;
    }
    // 初回より後の日付を設定
    startDate = endDate;
    endDate = schedule[PhaseEnum[idx]];

    // 日付に含まれていればそのindexを返す。startDate <= targetDate < endDate
    if (
      moment(targetDate).isBetween(
        moment(startDate),
        moment(endDate),
        "day",
        "[)"
      )
    ) {
      break;
    }
    result = idx;
  }
  // resultの値でどのフェーズか判定
  if (result >= 0 && result < PhaseEnum.length) {
    return PhaseEnum[result];
  } else {
    return null;
  }
};

/**
 * toDateを以下の観点でvalidateする
 * - 前後のphaseに含まれているか
 * - 移動元が終点だったら開始日を、始点だったら終了日を越してはいけない
 * phaseによっては日にちが重なっていたりするのでそれも考慮している
 * @param schedule スケジュール情報
 * @param toDate 移動先の日にち
 * @param phase 移動させるフェーズ
 * @param isFuture 未来方向か過去方向か
 * @return 移動可能=true, 移動不可=false
 */
export const validateToDate = (
  schedule: Schedule,
  toDate: string,
  phase: PhaseType,
  isFuture: boolean
): boolean => {
  /** 対象フェーズ */
  let phaseIndex: Number = -1;
  let momentBeforeDate: Moment | null | undefined = undefined;
  let isTargetPhase: boolean = false;

  const momentToDate = moment(toDate);

  // isFuture(未来/過去)によってループの向きを変える 未来最初から、過去最後から
  for (
    let i: number = isFuture ? 0 : PhaseEnum.length - 1;
    isFuture ? i < PhaseEnum.length : i >= 0;
    isFuture ? i++ : i--
  ) {
    // 処理開始位置
    if (!isTargetPhase) {
      if (phase === PhaseEnum[i]) {
        isTargetPhase = true;
        momentBeforeDate = moment(schedule[PhaseEnum[i]]);
        phaseIndex = i;
      }
      continue;
    }
    // 日付が未設定の場合 または 確認対象が同じ日の場合は確認不要なので次の日付を確認する
    if (
      !schedule[PhaseEnum[i]] ||
      momentBeforeDate?.isSame(moment(schedule[PhaseEnum[i]]))
    ) {
      continue;
    }
    // 移動先から未来なら過去方向・過去なら未来方向をみて日付の設定がある場合は移動不可
    return isFuture
      ? momentToDate.isSameOrBefore(moment(schedule[PhaseEnum[i]]))
      : momentToDate.isSameOrAfter(moment(schedule[PhaseEnum[i]]));
  }
  // いずれの日付にも条件が合致しない場合フリー
  return true;
};

/**
 * スケジュールを移動させる
 * - phaseの重なりについて
 *   - 未来方向への移動は見えているphase(一番後のphase)のみ更新、過去方向への移動は全てまとめて更新
 *   - 対象が開始日の場合…そのphaseの日にちを更新、ただし前のphaseがある場合はその日にちも更新
 *   - 対象が終了日の場合…その後のphaseの日にちを更新
 *   - 対象が開始日であり終了日の場合…そのphaseの日にちを更新、未来 or 過去方向によって更新が必要なphaseを更新する
 * @param phase 移動させるフェーズ
 * @param schedule スケジュール情報
 * @param toDate 移動先の日にち
 * @param isFuture 未来方向か過去方向か
 */
const moveSchedule = (
  phase: string,
  schedule: Schedule,
  toDate: string,
  isFuture: boolean
): void => {
  let beforeDate: Moment | null | undefined = undefined;
  // isFuture(未来/過去)によってループの向きを変える
  for (
    let i: number = isFuture ? 0 : PhaseEnum.length - 1;
    isFuture ? i < PhaseEnum.length : i >= 0;
    isFuture ? i++ : i--
  ) {
    // 自分の日付を探す
    if (!beforeDate) {
      if (PhaseEnum[i] === phase) {
        beforeDate = moment(schedule[PhaseEnum[i]]);
        schedule[PhaseEnum[i]] = toDate;
      }
      continue;
    }
    // 同じ日付があれば一緒に移動する
    // * 未来方向は見えている日付が一番上になるので動作しない
    if (beforeDate.isSame(moment(schedule[PhaseEnum[i]]))) {
      schedule[PhaseEnum[i]] = toDate;
    }
  }
};

// scheduleを移動さきによって更新する
export const getUpdatedSchedule = async (
  fromDate: string,
  toDate: string,
  row: any
): Promise<Schedule> => {
  // どのphaseがどの日にちからどの日にちに動こうとしているか取得

  // fromDateとtoDateが同じなら何もしない
  if (fromDate === toDate) return Promise.reject();

  // 移動が過去方向か未来方向か確認
  const isFuture = moment(fromDate).isBefore(toDate);

  // fromDateからどのフェーズか確認
  const phase = getPhase(row, fromDate);
  if (!phase) return Promise.reject();

  // toDateは前後のphaseの期間でないといけない
  if (!validateToDate(row, toDate, phase, isFuture)) {
    return Promise.reject();
  }

  // 変更対象のschedule取得
  const targetSchedule = await getScheduleById(row.id);
  if (targetSchedule === undefined) {
    return Promise.reject();
  }

  // 各phaseの日にちを更新していく
  moveSchedule(phase, targetSchedule, toDate, isFuture);

  const input: UpdateScheduleInput = {
    id: targetSchedule.id,
    projectId: targetSchedule.projectId,
    name: targetSchedule.name,
    m3: targetSchedule.m3,
    case: targetSchedule.case,
    shipType: targetSchedule.shipType,
    beforeStockingDate: targetSchedule.beforeStockingDate,
    stockingDate: targetSchedule.stockingDate,
    beforePackagingDate: targetSchedule.beforePackagingDate,
    packagingDate: targetSchedule.packagingDate,
    beforeShippingDate: targetSchedule.beforeShippingDate,
    shippingDate: targetSchedule.shippingDate,
    beforeCutDate: targetSchedule.beforeCutDate,
    cutDate: targetSchedule.cutDate,
    afterCutDate: targetSchedule.afterCutDate,
    numImgs: targetSchedule.numImgs,
    isShipped: targetSchedule.isShipped,
  };
  await updateScheduleByInput(input);
  const resultSchedule = await getScheduleById(row.id);
  if (resultSchedule === undefined) {
    return Promise.reject();
  }
  return resultSchedule;
};
