import { useState } from "react";
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridPaginationModel,
} from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import columns from "./Columns";

import { ProjectsByGroupIdForManagerOutput } from "API";
import { dateStringToJaDateStr } from "utils/date";
import { dataGridToolbar } from "utils/toolbar";
import { useAuth } from "contexts/auth";
import { SearchBox } from "components/SearchBox";
import {
  ModelProjectConditionInput,
  ModelUserConditionInput,
  ModelScheduleConditionInput,
} from "API";
import { getFullName } from "utils/user";
import { projectsByGroupIdForManager } from "graphql/queries";
import { useQueryWrap } from "hooks/request";

type CursorInfo = {
  nextToken: string | null;
  startIndex: number;
};

type FilterCondition = {
  projectFilter?: ModelProjectConditionInput[];
  userFilter?: ModelUserConditionInput[];
  categoryFilter?: ModelProjectConditionInput[];
  scheduleFilter?: ModelScheduleConditionInput[];
};

const dataToRows = (data: ProjectsByGroupIdForManagerOutput["items"]) => {
  if (!data) return [];
  const rows = data.map((d) => ({
    id: d?.id,
    managerName: d?.managerName,
    quoterName: d?.quoterName,
    operatorName: d?.operatorName,
    accountId: d?.accountId,
    accountName: d?.accountName,
    contactId: d?.contactId,
    contactName: d?.contactName,
    name: d?.name,
    status: d?.status,
    alert: d?.alert,
    reference: d?.reference,
    type: d?.type,
    createdAt: d?.createdAt ? dateStringToJaDateStr(d.createdAt) : null,
    updatedAt: d?.updatedAt ? dateStringToJaDateStr(d.updatedAt) : null,
    account: d?.account,
    schedules: d?.schedules,
    user: getFullName(d?.user),
    category: d?.category?.name,
  }));
  return rows;
};

const DEFAULT_PAGINATION_MODEL = {
  page: 0,
  pageSize: 100,
};

export function Table() {
  const { permissions, currentGroup, user } = useAuth();
  const [paginationModel, setPaginationModel] = useState(
    DEFAULT_PAGINATION_MODEL
  );
  // ページごとのnextTokenを管理
  const [cursorInfo, setCursorInfo] = useState<{ [key: string]: CursorInfo }>(
    {}
  );
  const [filterCondition, setFilterCondition] = useState<FilterCondition>({});

  //PAX-538 デフォルトで表示しない列はここで指定する
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      managerName: false,
      quoterName: false,
      operatorName: false,
      user: false,
      alert: false,
      caseMarkNumImgs: false,
      stockNumImgs: false,
      archived: false,
      cancelled: false,
      cancelReason: false,
    });

  const generateCacheKey = (page: number) => {
    return JSON.stringify({
      query: "projectsByGroupIdForManager",
      groupId: currentGroup?.id,
      page,
      pageSize: paginationModel.pageSize,
      filterCondition: filterCondition,
    });
  };

  const hasNextPage =
    cursorInfo[generateCacheKey(paginationModel.page)]?.nextToken !== null;

  const queryCallback = (data: ProjectsByGroupIdForManagerOutput) => {
    const newNextToken = data.nextToken ?? null;
    const newStartIndex = data.startIndex ?? 0;
    // 新しいトークンが存在する場合にnextTokensを更新
    setCursorInfo((prevCursorInfo) => {
      if (generateCacheKey(paginationModel.page) in cursorInfo) {
        return prevCursorInfo;
      }
      return {
        ...prevCursorInfo,
        [generateCacheKey(paginationModel.page)]: {
          nextToken: newNextToken,
          startIndex: newStartIndex,
        },
      };
    });
    return data.items;
  };

  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    // 1ページあたりの表示数が変わるときはトークンとページをリセット
    if (newPaginationModel.pageSize !== paginationModel.pageSize) {
      setPaginationModel({ ...newPaginationModel, page: 0 });
    } else {
      setPaginationModel(newPaginationModel);
    }
  };

  const resetPaginationModel = () => {
    setPaginationModel(DEFAULT_PAGINATION_MODEL);
  };

  const { data: projects, isLoading } = useQueryWrap<
    ProjectsByGroupIdForManagerOutput,
    ProjectsByGroupIdForManagerOutput["items"]
  >(
    [
      "projectsByGroupIdForManager",
      currentGroup?.id,
      paginationModel.page,
      paginationModel.pageSize,
      filterCondition,
    ],
    projectsByGroupIdForManager,
    {
      groupId: currentGroup?.id,
      tenantId: user?.tenantId,
      filter: { ...filterCondition.projectFilter, archived: { ne: true } },
      userFilter: filterCondition.userFilter,
      categoryFilter: filterCondition.categoryFilter,
      scheduleFilter: filterCondition.scheduleFilter,
      limit: paginationModel.pageSize,
      sortDirection: "DESC",
      nextToken:
        cursorInfo[generateCacheKey(paginationModel.page - 1)]?.nextToken ??
        null,
      startIndex:
        cursorInfo[generateCacheKey(paginationModel.page - 1)]?.startIndex ?? 0,
    },
    queryCallback
  );

  if (!projects && !isLoading) return null;
  return (
    <>
      <SearchBox
        setFilterCondition={setFilterCondition}
        resetPaginationModel={resetPaginationModel}
      />
      {isLoading ? (
        <LinearProgress />
      ) : (
        <DataGrid
          key={hasNextPage ? "hasNextPage" : "noNextPage"}
          className="bg-white"
          slots={{ toolbar: dataGridToolbar }}
          slotProps={{
            loadingOverlay: {
              variant: "skeleton",
              noRowsVariant: "skeleton",
            },
          }}
          rows={dataToRows(projects)}
          columns={columns(permissions.MasterData)}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          initialState={{
            pagination: { rowCount: -1 },
            density: "compact",
          }}
          // PAX-538 列選択をできないようにしている(1列だけ掴むとCSV出力などがうまくいかないため)
          isRowSelectable={() => false}
          // paginationの設定
          paginationMode="server"
          pageSizeOptions={[25, 50, 100]}
          paginationMeta={{ hasNextPage }}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
        />
      )}
    </>
  );
}

export default Table;
