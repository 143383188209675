import * as React from "react";
import { useState, Dispatch, SetStateAction } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import { getStatus } from "utils/status";

type SearchBoxProps = {
  resetPaginationModel: () => void;
  setFilterCondition: Dispatch<SetStateAction<any>>;
};

export function SearchBox({
  resetPaginationModel,
  setFilterCondition,
}: SearchBoxProps) {
  const [keyword, setKeyword] = useState("");

  const handleSearch = async () => {
    if (!keyword) {
      setFilterCondition({});
    } else {
      setFilterCondition({
        projectFilter: {
          or: [
            {
              name: {
                contains: keyword,
              },
            },
            {
              accountName: {
                contains: keyword,
              },
            },
            {
              contactName: {
                contains: keyword,
              },
            },
            {
              reference: {
                contains: keyword,
              },
            },
            {
              managerName: {
                contains: keyword,
              },
            },
            {
              quoterName: {
                contains: keyword,
              },
            },
            {
              operatorName: {
                contains: keyword,
              },
            },
            {
              status: {
                contains: getStatus(keyword),
              },
            },
          ],
        },
        userFilter: {
          or: [
            {
              firstName: {
                contains: keyword,
              },
            },
            {
              lastName: {
                contains: keyword,
              },
            },
            {
              name: {
                contains: keyword,
              },
            },
          ],
        },
        categoryFilter: {
          or: [
            {
              name: {
                contains: keyword,
              },
            },
          ],
        },
        scheduleFilter: {
          or: [
            {
              beforeStockingName: {
                contains: keyword,
              },
            },
            {
              beforePackagingName: {
                contains: keyword,
              },
            },
            {
              beforeShippingName: {
                contains: keyword,
              },
            },
            {
              beforeCutName: {
                contains: keyword,
              },
            },
            {
              afterCutName: {
                contains: keyword,
              },
            },
          ],
        },
      });
    }
    resetPaginationModel();
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 300,
        m: "10px 0px",
      }}
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="案件を検索する"
        inputProps={{ "aria-label": "search projects" }}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={handleSearch}
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
